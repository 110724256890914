/* print styles */

@import "base/settings";
@import "base/mixins.scss";
@import "utils/functions";
@import "base/fonts.scss";

@import "../node_modules/leaflet/dist/leaflet.css";
@import url('https://www.lindenberg-eichsfeld.de/f_css_defaults') screen, print;

nav,
.footer { display: none; }

.container { width: 100%; }


